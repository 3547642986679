import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "is-flex is-align-items-center" }
const _hoisted_2 = { class: "image is-16x16 mr-2" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(!_ctx.multiple ? 'o-autocomplete' : 'o-inputitems'), {
    ref: "dropdown",
    modelValue: _ctx.newModelValue,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newModelValue) = $event)),
      _ctx.onInput
    ],
    "append-to-body": _ctx.appendToBody,
    autocomplete: _ctx.multiple ? 'autocomplete' : null,
    "check-infinite-scroll": true,
    clearable: true,
    "close-icon": "",
    data: _ctx.data,
    field: "name",
    icon: "globe-europe",
    "icon-right": "caret-down",
    loading: _ctx.loading,
    "open-on-focus": true,
    placeholder: "Country",
    onSelect: _ctx.onSelect,
    onTyping: _ctx.onTyping,
    onInfiniteScroll: _ctx.fetchMoreData,
    onFocus: _withModifiers(_ctx.onFocus, ["self"])
  }, {
    default: _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: `/img/flags/${props.option.code}.svg`,
            alt: props.option.code,
            class: "is-fullheight"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("span", null, _toDisplayString(props.option.name), 1)
      ])
    ]),
    empty: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Loading... "))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, " No matching results "))
    ]),
    _: 1
  }, 40, ["modelValue", "append-to-body", "autocomplete", "data", "loading", "onSelect", "onTyping", "onInfiniteScroll", "onUpdate:modelValue", "onFocus"]))
}