
import { debounce } from 'ts-debounce';
import { defineComponent, PropType } from 'vue';
import { ICountriesResponse, ICountry } from '@/interfaces/country';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    props: {
        appendToBody: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'update:modelValue',
    ],
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            newModelValue: undefined,
            data: [] as Array<ICountry>,
        };
    },
    watch: {
        modelValue(value): void {
            if (this.multiple) {
                this.newModelValue = value || [];
            } else {
                this.newModelValue = value ? value.name : '';
            }
        },
    },
    mounted() {
        if (this.multiple) {
            this.newModelValue = this.modelValue ? this.modelValue : [];
        } else {
            this.newModelValue = this.modelValue ? this.modelValue.name : '';
        }

        if (!this.modelValue) {
            this.$emit('update:modelValue', null);
        }
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: ICountry): void {
            if (!obj) this.reset();
            if (!this.multiple) {
                this.$emit('update:modelValue', obj || null);
            }
        },
        onInput(obj: any): void {
            if (this.multiple) {
                this.$emit('update:modelValue', obj || []);
            }
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<ICountriesResponse>('countries', {
                params: {
                    filter: this.name,
                    page: this.page,
                    per_page: 20,
                },
            })
                .then(({ data }) => {
                    this.data.push(...data.data);
                    this.page += 1;

                    this.totalPages = data.meta.last_page;
                });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),
    },
});
